<template>
	<div>
		<b-card>
			<b-row class="m-1 top-header">
				<!-- Date picker -->
				<b-col md="6" class="d-flex align-items-center cursor-pointer">
					<div @click="goBack">
						<feather-icon icon="ArrowLeftIcon" size="24" />
					</div>
					&nbsp;
					<h3 class="pr-1 mb-0">{{ machineInfo.machine_id }}</h3>
				</b-col>
				<!-- Search -->
				<b-col
					cols="12"
					md="6"
					class="d-flex justify-content-end align-items-center"
				>
					<!-- <div>
						<b-form-input
							v-model="searchQuery"
							class="d-inline-block"
							placeholder="Search..."
						/>
					</div> -->

					<div>
						<b-button
							variant="primary"
							:to="{
								name: `apps-machine-hatcher-device`,
								params: { machineId: this.machineId },
							}"
							class="mr-1 ml-2"
						>
							<feather-icon icon="PlusIcon" /> Change Device
						</b-button>
					</div>
					<!-- <div v-else>
						<b-button
							variant="primary"
							:to="{ path: `/apps/machine/hatcher/device/${this.hatch_id}` }"
							class="mr-1 ml-2"
						>
							<feather-icon icon="PlusIcon" /> Attach Device
						</b-button>
					</div> -->
				</b-col>
			</b-row>
			<div class="list-view m-1">
				<b-row>
					<b-col md="4">
						<div class="d-flex justify-content-between">
							<div>
								<span class="head-text">
									{{ machineInfo ? machineInfo.device_name : "No Device" }}
								</span>
							</div>
							<div v-if="machineInfo.hatchery_device" class="d-flex">
								<div class="mr-5">
									<span class="mr-1">
										<b-img
											:src="
												require('@/assets/images/svg/machine/temprature.svg')
											"
										/>
									</span>
									<span class="head-text">
										{{ machineInfo.hatchery_device.temp }}
										<sup
											style="color: #f6667e; font-size: 10px; margin-right: 3px"
											>0</sup
										><span class="red-text">c</span></span
									>
								</div>
								<div>
									<span class="mr-1">
										<b-img
											:src="require('@/assets/images/svg/machine/humidity.svg')"
										/>
									</span>
									<span class="info-text">
										{{ machineInfo.hatchery_device.humidity }}
										<span style="font-size: 12px">%</span></span
									>
								</div>
							</div>
							<div v-else></div>
						</div>
					</b-col>
					<b-col md="8 d-flex justify-content-end">
						<div>
							<div
								v-if="machineInfo.machine_status === 'Active'"
								class="activeClass"
							>
								Active
							</div>
							<div v-else class="inactiveClass">Inactive</div>
						</div>
						<b-dropdown
							variant="link"
							no-caret
							right
							class="chart-dropdown ml-1"
							toggle-class="p-0"
						>
							<template #button-content>
								<feather-icon
									icon="MoreVerticalIcon"
									size="18"
									class="text-body cursor-pointer"
								/>
							</template>
							<b-dropdown-item v-b-modal.addHatcher>
								<div>
									<feather-icon icon="EditIcon" />
									<span class="align-middle ml-50">Edit</span>
								</div>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span
									><b-img
										:src="require('@/assets/images/svg/machine/egg.svg')"
										style="margin-right: 10px" /></span
								>Available Eggs
							</div>
							<div class="success-text">
								{{ machineInfo.available_egg_capacity }}
							</div>
						</div>
					</b-col>
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span>
									<b-img
										:src="require('@/assets/images/svg/machine/available.svg')"
										style="height: 17px; width: 17px"
										class="mr-2" /></span
								>Tray Capacity
							</div>
							<div class="head-text">{{ machineInfo.tray_capacity }}</div>
						</div>
					</b-col>
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span
									><b-img
										:src="require('@/assets/images/svg/machine/occupied.svg')"
										style="height: 17px; width: 17px"
										class="mr-2" /></span
								>Egg Capacity
							</div>
							<div class="head-text">{{ machineInfo.egg_capacity }}</div>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="m-2">
				<b-table
					:items="hatchInfo"
					responsive
					:fields="fields"
					hover
					:per-page="perPage"
					:current-page="currentPage"
					primary-key="id"
					sort-by="id"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<!-- Column: Id -->
					<template #cell(index)="data">
						<div class="ml-1">
							{{ (currentPage - 1) * perPage + data.index + 1 }}
						</div>
					</template>
					<template #cell(candling)="data">
						<div class="ml-1">
							{{ data.item.total_candling_schedule_done }}/{{
								data.item.total_candling_schedule
							}}
						</div>
					</template>
					<template #cell(Vaccination)="data">
						<div class="ml-1">
							{{ data.item.total_vaccine_schedule_done }}/{{
								data.item.total_vaccine_schedule
							}}
						</div>
					</template>
					<!-- Column: Actions -->
					<template #cell(actions)="data">
						<router-link
							:to="{
								name: 'apps-machine-hatcher-device-overview',
								params: {
									hatchery_device_id: hatchery_device_id,
									machineId: data.item.hatchery_machine_id,
									hatchId: data.item.hatch_id,
								},
							}"
						>
							<feather-icon icon="EyeIcon" size="16"
						/></router-link>
					</template>
				</b-table>
			</div>
		</b-card>
		<!-- setter modal -->
		<b-modal
			id="addHatcher"
			title="Add Setter Details"
			size="lg"
			ok-title="Submit"
			@ok="addSetterMachine()"
		>
			<form ref="form" @submit="addSetterMachine" method="post">
				<b-form-group label-for="i-first-name" label="Setter ID">
					<b-form-input
						id="setter_name"
						placeholder=""
						v-model="machineData.machine_id"
					/>
				</b-form-group>

				<b-form-group label-for="egg-capacity" label="Egg Capacity">
					<b-form-input
						id="egg_capacity"
						placeholder=""
						v-model="machineData.egg_capacity"
					/>
				</b-form-group>

				<b-form-group label-for="No. of tray" label="No of tray">
					<b-form-input
						id="tray_no"
						placeholder=""
						v-model="machineData.tray_capacity"
					/>
				</b-form-group>
			</form>
		</b-modal>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BImg,
} from "bootstrap-vue";

import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BFormGroup,
		BTooltip,
		BFormDatepicker,

		BImg,
	},
	data() {
		return {
			perPage: 5,
			currentPage: 1,
			selected: null,
			hatch_id: null,
			searchQuery: null,
			hatchery_device_id: null,
			fields: [
				{ key: "index", label: "S.no" },
				{ key: "hatch_id", label: "Hatch ID" },
				{
					key: "created_at",
					label: "created at",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				"days_left",

				"candling",

				"Vaccination",

				"actions",
			],
			machineId: "",
			machineInfo: {},
			hatchInfo: {},
			deviceId: null,
			machineData: {
				machine_id: null,
				egg_capacity: null,
				tray_capacity: null,
				machine_type: "setter",
			},
		};
	},
	created() {
		this.machineId = this.$route.query.machineId;
		this.hatchery_device_id = this.$route.query.hatchery_device_id;
		this.getHatcherMachine();
	},

	methods: {
		moment() {
			return moment();
		},
		goBack() {
			this.$router.go(-1);
		},
		getHatcherMachine() {
			const machineId = this.$route.query.machineId;
			axiosIns
				.get(`web/getInformation/Machine/${machineId}`)
				.then((response) => {
					this.machineInfo = response.data.machine_info;
					this.hatchInfo = response.data.machine_info.hatcher_detail;
					this.hatch_id = response.data.machine_info.id;
					this.deviceId = response.data.machine_info.hatchery_device.device_id;
				})
				.catch((error) => {
					error;
				});
		},
		addSetterMachine() {
			let formData = new FormData();
			this.machineData.hatchery_id = this.$route.params.hatcheryId;
			let form_data = this.objectToFormData(this.machineData, formData);

			axiosIns
				.post(`web/add-machine`, form_data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `Machine Successfully Added `,
						},
					});
					this.getSetterMachine();
				})
				.catch((error) => {
					const data = error.response.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.list-view {
	background-color: #fafafa;
	box-shadow: 0px 3px 6px #38c06c33;
	height: 133px;
	padding: 1rem 1rem;
	margin: auto;
}
.head-text {
	font-size: 18px;
	color: #1d1d1d;
}
.red-text {
	color: #f6667e;
	font-size: 10px;
}
.info-text {
	color: #1c54e4;
	font-size: 19px;
}
.success-text {
	color: #38c06c;
	font-size: 15px;
}
.svg-img {
	width: 17px;
	height: 17px;
	// margin-right: 10px
}
.attach-device {
	background-color: #38c06c;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	padding: 5px 5px;
	justify-content: center;
	border-radius: 4px;
}
.activeClass {
	background-color: #38c06c;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 54px;
	height: 23px;
	justify-content: center;
	border-radius: 4px;
}
.inactiveClass {
	background-color: #dd2e61;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 54px;
	height: 23px;
	justify-content: center;
	border-radius: 4px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
